<template>
  <div>
    <div class="bg-catalog"></div>
    <div class="card">
      <Loading v-if="loading"/>
      <div v-else>
        <NotFound v-if="notFound"/>
        <div v-else>
          <div class="text-center mb-4">
            <img :src="getImage(catalog.logo)" class="img-thumbnail rounded-circle square mb-2">
            <p class="m-0 p-0">{{ catalog.name }}</p>
            <small class="text-muted">{{ catalog.description }}</small>
          </div>
          <p class="text-muted" v-if="checkEmptyItems()">{{ FA_LAN.ITEM.EMPTY_ITEM }}</p>
          <div v-else>
            <div class="d-flex mb-3 scroll">
              <button class="btn btn-outline-dark ms-2" id="btnCategory0"
                      @click="showItems(0)">{{ FA_LAN.ITEM.ALL_ITEMS }}
              </button>
              <button v-for="category in categories" :key="category.id" :id="'btnCategory'+category.id"
                      class="btn btn-outline-secondary ms-2" @click="showItems(category.id)">
                {{ category.name }}
              </button>
            </div>
            <Loading class="pt-4" v-if="itemLoading" :not-full="true"/>
            <div v-else>
              <p class="text-muted pt-2" v-if="checkEmptyCategoryItems()">{{ FA_LAN.ITEM.EMPTY_CATEGORY_ITEM }}</p>
              <ShowItem v-else v-for="item in itemsToShow" :key="item.id" :item="item"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PowerBy/>
  </div>

</template>

<script>
import Loading from "./Loading";
import {onMounted, ref} from "vue";
import {post} from "../store/webservice";
import NotFound from "./NotFound";
import ShowItem from "./ShowItem";
import persian from "../fa-IR";
import {getDefaultImage} from "../js/image/image";
import {setCssColor} from "../js/css/color";
import PowerBy from "./PowerBy";

export default {
  name: "ShowCatalog",
  components: {PowerBy, ShowItem, NotFound, Loading},
  props: ['username'],
  setup(props) {
    const FA_LAN = persian;
    const loading = ref(true);
    const categories = ref(null);
    const items = ref(null);
    const notFound = ref(false);
    const catalog = ref(null);
    const itemsToShow = ref(null);
    const itemLoading = ref(false);
    const activeBtn = ref('btnCategory0');

    onMounted(async () => {
      await getCatalog();

      if (notFound.value) {
        loading.value = false
        return;
      }

      await getCategory();
      await getItems();

      await showItems(0, false);

      loading.value = false
    })

    async function getCatalog() {
      await post('v1/view/getCatalog', {username: props.username})
          .then(response => {
            if (response.data.code === 200) {
              catalog.value = response.data.data.catalog;

              setCssColor(catalog.value.background_color, catalog.value.text_color)

            } else {
              notFound.value = true
            }
          })
    }

    async function getCategory() {
      await post('v1/view/getCategory', {catalog_id: catalog.value.id})
          .then(response => {
            categories.value = response.data.data.categories;
          })

    }

    async function getItems() {
      await post('v1/view/getItems', {catalog_id: catalog.value.id})
          .then(response => {
            items.value = response.data.data.items;
          })

    }

    function checkEmptyItems() {
      if (!items.value) {
        return true;
      }

      return items.value.length === 0;
    }

    function checkEmptyCategoryItems() {
      if (!itemsToShow.value) {
        return true;
      }

      return itemsToShow.value.length === 0;
    }

    function getImage(image) {
      return image ? image : getDefaultImage('catalog')
    }

    async function showItems(categoryId, setStyle = true) {
      itemLoading.value = true;

      if (categoryId === 0) {
        itemsToShow.value = items.value;

      } else {
        itemsToShow.value = items.value.filter(item => item.catalog_category_id == categoryId);

      }

      setActiveStyle(categoryId, setStyle);

      await timeout(400);

      itemLoading.value = false;
    }

    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    function setActiveStyle(categoryId, setStyle) {
      if (!setStyle) {
        return;
      }

      //remove old btn style
      const otherBtn = document.getElementById(activeBtn.value);
      otherBtn.classList.remove('btn-outline-dark');
      otherBtn.classList.add('btn-outline-secondary');

      //add active style
      const btn = document.getElementById('btnCategory' + categoryId);
      btn.classList.remove('btn-outline-secondary');
      btn.classList.add('btn-outline-dark');

      activeBtn.value = 'btnCategory' + categoryId;
    }

    return {
      FA_LAN,
      loading,
      notFound,
      itemsToShow,
      catalog,
      itemLoading,
      categories,
      showItems,
      getImage,
      checkEmptyItems,
      checkEmptyCategoryItems,
    }
  }
}
</script>

<style scoped>

</style>