export function setCssColor(backgroundColor, textColor) {
    const root = document.querySelector(':root');
    root.style.setProperty('--background-color', backgroundColor);
    root.style.setProperty('--text-color', textColor);
}

export function getCssColor() {
    const root = getComputedStyle(document.querySelector(':root'));
    let backColor = root.getPropertyValue('--background-color');
    let textColor = root.getPropertyValue('--text-color');

    //remove extra space
    backColor = backColor.replace(" ", "");
    textColor = textColor.replace(" ", "");

    return {background: backColor, text: textColor}
}