import axios from "axios";
import store from "./index";

// const baseUrl = "http://localhost:8000/";
const baseUrl = "https://app.instacatalog.ir/api/";

function getAPIkey() {
    return store.getters["apiKey/get"];
}

export async function post(url, data, file = false) {
    const apiKey = getAPIkey();

    let header = '';
    if (apiKey != null) {
        if (file === true) {
            header = {
                'Content-Type': 'multipart/form-data',
                'api-key': apiKey
            }
        } else {
            header = {'api-key': apiKey}
        }
    }

    return await axios.post(baseUrl + url, data, {headers: header});
}

export async function get(url) {
    const apiKey = getAPIkey();
    const platform = '&platform=web'

    let header = '';
    if (apiKey != null) {
        header = {'api-key': apiKey}
    }

    return await axios.get(baseUrl + url + platform, {headers: header});
}

export async function recaptchChek(token) {
    let url = "https://app.instacatalog.ir/googlePlay/recaptcha.php";
    let data = {token: token}
    return await axios.post(url, data);
}

export async function getNegahProduct() {
    let url = "https://negahgroup.ir/api/getOurProduct";

    return await axios.post(url);
}