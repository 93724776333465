<template>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <Footer/>
</template>

<style>
.fade-enter-active {
  transition: all .8s ease;
}

.fade-enter-from {
  opacity: 0;
}

.loader {
  left: 50%;
  margin-left: -4em;
}
</style>
<script>
import Footer from "./components/Footer";

export default {
  components: {Footer}
}
</script>