<template>
  <div>
    <div class="spacer m-0"></div>
    <div class="row align-items-center">
      <div class="col-md-4 col-12 mt-md-0 mt-2">
        <a :href="getImage(item.images)" target="_blank"> <img :src="getImage(item.images)"></a>
      </div>
      <div class="col-md-8 col-12 mt-4 mb-4 ps-3">
        <p class="p-0 m-0 mb-2">{{ item.name }}<span class="text-muted small ms-1">({{ item.category_name }})</span></p>
        <small class="text-muted">{{ item.description }}</small>
        <div class="d-flex mt-2 p-2 bg-light border-2">
          <p class="col-6 m-0 p-0" :class="{'text-decoration-line-through small text-muted':priceDiff()}">
            <span>{{ FA_LAN.ITEM.MAIN_PRICE_CATALOG }}</span>
            <span v-if="checkFree(item.main_price)">
              <span class="ms-1">{{ FA_LAN.ITEM.FREE }}</span>
            </span>
            <span v-else>
              <span class="ms-1">{{ addComma(item.main_price) }}</span>
              <span class="ms-1">{{ FA_LAN.ITEM.TOMAN }}</span>
            </span>
          </p>
          <p class="col-6 m-0 p-0 off-price" v-if="priceDiff()">
            <span>{{ FA_LAN.ITEM.PRICE_CATALOG }}</span>
            <span v-if="checkFree(item.price)">
              <span class="ms-1">{{ FA_LAN.ITEM.FREE }}</span>
            </span>
            <span v-else>
             <span class="ms-1">{{ addComma(item.price) }}</span>
             <span class="ms-1">{{ FA_LAN.ITEM.TOMAN }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR";
import {numberWithCommas} from "../js";
import {getDefaultImage} from "../js/image/image";

export default {
  name: "ShowItem",
  props: ['item'],
  setup(props) {
    const FA_LAN = persian;

    function priceDiff() {
      return props.item.price !== props.item.main_price;
    }

    function checkFree(price) {
      return price == 0;
    }

    function addComma(price) {
      return numberWithCommas(price)
    }

    function getImage(images) {
      if (images) {
        return images.length !== 0 ? images[0] : getDefaultImage('item')
      } else {
        return getDefaultImage('item')
      }
    }

    return {FA_LAN, priceDiff, checkFree, addComma, getImage}
  }
}
</script>

<style scoped>

</style>