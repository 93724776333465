<template>
  <div class="text-center fixed-bottom footer-bg">
    <a href="https://negahgroup.ir" target="_blank"
       class="btn btn-link text-muted text-decoration-none"><small>{{ FA_LAN.MAIN.FOOTER }}</small></a>
  </div>
</template>

<script>
import persian from "@/fa-IR";

export default {
  name: "Footer",
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>