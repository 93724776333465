<template>
  <router-view>
    <transition name="fade">
      <ShowCatalog v-if="hasUsername" :username="username"/>
      <Introduction v-else/>
    </transition>
  </router-view>
</template>

<script>

import {useRoute} from "vue-router";
import Introduction from "../components/Introduction";
import ShowCatalog from "../components/ShowCatalog";
import {computed, onMounted} from "vue";

export default {
  name: 'Home',
  components: {ShowCatalog, Introduction},
  setup() {
    const route = useRoute();
    const username = computed(() => route.params.username);
    const hasUsername = username.value ? username.value : false;

    onMounted(() => {
      scrollToTop();
    })

    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    return {hasUsername, username}
  }
}
</script>
