<template>
  <div class="text-center p-0">
    <div class="d-grid gap-2 d-flex" :class="{'justify-content-center':header,'justify-content-end':!header}">
      <button class="btn btn-lg mt-2" @click="toPanel" :class="{'btn-primary':header,'btn-info':!header}">
        <p class="m-0">{{ FA_LAN.INTRODUCTION.REGISTER_BUTTON }}</p>
      </button>
      <button v-if="header" class="btn btn-lg ms-2 mt-2 btn-light" @click="toPanel">
        <p class="m-0">{{ FA_LAN.INTRODUCTION.LOGIN_BUTTON }}</p>
      </button>
    </div>
    <p v-if="header" class="mt-2">{{ FA_LAN.INTRODUCTION.REGISTER_NOW }}</p>
  </div>
</template>

<script>
import persian from "../fa-IR";
import {onMounted, ref} from "vue";

export default {
  name: "RegisterButtons",
  props: ['position'],
  setup(props) {
    const FA_LAN = persian;
    const header = ref(true);

    function toPanel() {
      location.href = 'https://my.instacatalog.ir'
    }

    onMounted(() => {
      if (props.position !== 'header') {
        header.value = false;
      }
    })

    return {FA_LAN, header, toPanel}
  }
}
</script>

<style scoped>
.btn-primary{
  background-color: #5D49D0;
  border-color: #5D49D0;
}
</style>