<template>
  <Loading/>
</template>

<script>
import Loading from "../components/Loading";
import {useRouter} from "vue-router";
import {onBeforeMount} from "vue";

export default {
  name: "Redirect",
  components: {Loading},
  setup() {
    const router = useRouter();

    onBeforeMount(() => {
      router.push({name: 'Home'})
    })
  }
}
</script>

<style scoped>

</style>