export function numberWithCommas(x) {
    //remove old comma
    x = x.toString().replace(",", "");

    //add new comma
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function makeRandomString(length) {
    var result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }

    return result;
}