const introduction = {
    MAIN_TITLE: "بی‌نهایت محصول فقط در یک لینک",
    MAIN_DESCRIPTION: "با اینستا کاتالوگ تمام محصولاتت را با قیمت بروز به مشتری‌هات نمایش بده",
    REGISTER_NOW: "همین الان رایگان و راحت کاتالوگ خودت رو بساز!",
    SAMPLE_CATALOG: "مشاهده نمونه کاتالوگ: ",
    REGISTER_BUTTON: "ثبت‌نام رایگان و ایجاد کاتالوگ",
    LOGIN_BUTTON: "ورود به پنل",
    INCREASE_YOUR_SELL: "فروشت رو افزایش بده!",
    INCREASE_YOUR_SELL_DES: "می دونی وقتی میگی برای اطلاع از قیمت بیا دایرکت، یعنی از دست دادن مشتری؟",
    PEOPLE_NO_DIRECT: "اکثر افراد دایرکت نمیان؟",
    DIRECT_IS_WRONG: "هدایت به دایرکت، تلگرام یا واتس اپ بزرگ‌ترین اشتباه شما برای فروشه",
    PEOPLE_NO_DIRECT_R1: "همیشه سریع در دسترسی؟ ۲۴ ساعت ۷ روز هفته!",
    PEOPLE_NO_DIRECT_R2: "همه محصولاتت رو می تونی توی دایرکت معرفی کنی؟",
    PEOPLE_NO_DIRECT_R3: "اگر قیمت هات بروز شد چطور می‌خواهی به همه اطلاع بدی؟",
    PEOPLE_NO_DIRECT_R4: "می دونی خیلی از ادما دوست دارن توی سایه خرید کنن؟",
    ABOUT_US: "ما چند تا جوان برنامه‌نویس با تخصص‌های مختلف هستیم که عاشق چالش، خلاقیت، ابتکار و تست ایده‌های جدید هستیم و شب و روزمون رو برای اجرای ایده‌های شاید محال یا ناممکن و البته سخت خودمون گذاشتیم و اعتقاد داریم که: نمیشه وجود نداره!",
    ABOUT_US_TITLE: "درباره ما (گروه نگاه)",
    CONTACT_US: " راه‌های تماس  با ما",
    WHATSAPP: "واتس اپ",
    WHATSAPP_PHONE:"۲۸۱۸ ۶۲۴ ۰۹۳۰",
    TELEGRAM: "تلگرام",
    TELEGRAM_ID: "negahgroup_ir@",
    NEGAH_PRODUCT:"سایر اپلیکیشن‌های گروه نگاه",
}

export default introduction