<template>
  <div class="mb-5">
    <div class="vh-100 background-color d-flex align-items-center justify-content-center text-white">
      <div class="row justify-content-between align-items-center p-2 m-0">
        <div class="col-md-6">
          <h1 class="pb-3"><strong>{{ FA_LAN.INTRODUCTION.MAIN_TITLE }}</strong></h1>
          <h5 class="pb-4">{{ FA_LAN.INTRODUCTION.MAIN_DESCRIPTION }}</h5>
          <RegisterButtons position="header"/>
          <div class="pt-md-4 pt-3">
            <a :href="urls.sampleCatalog" target="_blank" class="text-decoration-none">
              <div class="alert alert-info text-center">
                {{ FA_LAN.INTRODUCTION.SAMPLE_CATALOG }}{{ urls.sampleCatalog }}
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-5 col-9 ms-md-5 mt-2 text-center mx-auto">
          <a :href="urls.headerImage" target="_blank">
            <video class="inline-video" data-lazy-type="video" autoplay="" loop="" muted="muted" playsinline=""
                   controlslist="nodownload" :src="urls.headerVideo"></video>
          </a>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div>
        <div class="d-flex align-items-center justify-content-center pt-5 pb-md-5 pb-3">
          <div class="row col-md-9 justify-content-between align-items-center p-2 m-0 mb-md-5 mt-md-5 mb-3 mt-3">
            <div class="col-md-5">
              <h3>{{ FA_LAN.INTRODUCTION.INCREASE_YOUR_SELL }}</h3>
              <h6 class="text-muted pb-2">{{ FA_LAN.INTRODUCTION.INCREASE_YOUR_SELL_DES }}</h6>
              <div class="spacer m-md-3 m-1 desktop"></div>
              <RegisterButtons class="desktop"/>
            </div>
            <div class="col-md-6 ms-md-4 mt-2 text-center" id="93664708059">
              <component :is="script" :src="urls.teaser"></component>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer mobile"></div>
      <div>
        <div class="d-flex align-items-center justify-content-center pt-md-5 pb-md-5 pt-3">
          <div class="row col-md-9 justify-content-between align-items-center p-2 m-0 mb-md-5 mt-md-5">
            <!--            for correct order in mobile-->
            <div class="col-md-5 mobile">
              <h3 class="pt-4">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT }}</h3>
              <h6 class="text-muted">{{ FA_LAN.INTRODUCTION.DIRECT_IS_WRONG }}</h6>
              <ul class="text-muted mt-3">
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R1 }}</li>
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R2 }}</li>
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R3 }}</li>
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R4 }}</li>
              </ul>
            </div>
            <!--          end for correct order in mobile-->
            <div class="col-md-6 ms-md-4 mt-2 text-center" id="29437393548">
              <component :is="script" :src="urls.whyUs"></component>
            </div>
            <div class="col-md-5 desktop">
              <h3 class="pt-4">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT }}</h3>
              <h6 class="text-muted">{{ FA_LAN.INTRODUCTION.DIRECT_IS_WRONG }}</h6>
              <ul class="text-muted mt-3">
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R1 }}</li>
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R2 }}</li>
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R3 }}</li>
                <li class="mb-1">{{ FA_LAN.INTRODUCTION.PEOPLE_NO_DIRECT_R4 }}</li>
              </ul>
              <div class="spacer m-md-3 m-1"></div>
              <RegisterButtons/>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile pt-4 pb-5">
        <div class="spacer"></div>
        <RegisterButtons position="header"/>
      </div>
    </div>
    <div v-if="negahProducts"
         class="col-md-9 mx-auto text-center   p-2 m-0 pb-5 pt-5 ">
      <h3>{{ FA_LAN.INTRODUCTION.NEGAH_PRODUCT }}</h3>
      <div class="spacer"></div>
      <div class="d-flex justify-content-center">
        <div class="d-flex mb-3 scroll">
          <div v-for="item in negahProducts" :key="item.id" class="ms-3 me-3">
            <a class="text-body text-decoration-none" :href="item.url" target="_blank">
              <div><img :src="item.image_square" class="products" :alt="item.name"/></div>
              <p class="m-0 mt-2 text-center">{{ item.name }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-footer text-white">
      <div class="row col-md-9 p-3 mx-auto">
        <div class="col-md-7">
          <h6>{{ FA_LAN.INTRODUCTION.ABOUT_US_TITLE }}</h6>
          <hr>
          <p> {{ FA_LAN.INTRODUCTION.ABOUT_US }}</p>
        </div>
        <div class="col-md-5 mt-md-0 mt-2">
          <h6>{{ FA_LAN.INTRODUCTION.CONTACT_US }}</h6>
          <hr>
          <div class="d-flex justify-content-around">
            <a class="btn btn-success" href="https://api.whatsapp.com/send?phone=+989306242818" target="_blank">
              <span class="desktop">{{ FA_LAN.INTRODUCTION.WHATSAPP }}  {{ FA_LAN.INTRODUCTION.WHATSAPP_PHONE }}</span>
              <span class="mobile">{{ FA_LAN.INTRODUCTION.WHATSAPP }}<br>{{ FA_LAN.INTRODUCTION.WHATSAPP_PHONE }}</span>
            </a>
            <a class="btn btn-info ms-2" href="tg://resolve?domain=negahgroup_ir" target="_blank">
              <span class="desktop">{{ FA_LAN.INTRODUCTION.TELEGRAM }}  {{ FA_LAN.INTRODUCTION.TELEGRAM_ID }}</span>
              <span class="mobile">{{ FA_LAN.INTRODUCTION.TELEGRAM }}<br>{{ FA_LAN.INTRODUCTION.TELEGRAM_ID }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR";
import RegisterButtons from "./RegisterButtons";
import {onMounted, reactive, ref} from "vue";
import {getNegahProduct} from "../store/webservice";

export default {
  name: "Introduction",
  components: {RegisterButtons},
  setup() {
    const FA_LAN = persian;
    const script = ref('script');
    const negahProducts = ref({});
    const allNegahProducts = ref({});
    const urls = reactive({
      sampleCatalog: "https://instacatalog.ir/negahgroup",
      teaser: "https://www.aparat.com/embed/kP7En?data[rnddiv]=93664708059&data[responsive]=yes",
      whyUs: "https://www.aparat.com/embed/QVCgc?data[rnddiv]=29437393548&data[responsive]=yes",
      headerVideo: "https://dl.negahgroup.ir/app-files/instacatalog/videos/introducation/header.mp4",
    })

    onMounted(async () => {
      await getNghProduct();
    })

    async function getNghProduct() {
      await getNegahProduct().then(response => {
        allNegahProducts.value = response.data.products;
        negahProducts.value = allNegahProducts.value.filter(item => item.code !== 'instacatalog')
      })
    }

    return {FA_LAN, script, urls, negahProducts}
  }
}
</script>

<style scoped>
.alert-info a {
  color: #055160;
}

.background-color {
  background-color: #49bcd0;
}

img {
  max-width: 500px;
}

img.products {
  width: 120px;
  border-radius: 10px;
}

.inline-video {
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
}

.bg-footer {
  background-color: #212529;
}
</style>