import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag-next";

import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./css/index.css"


const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueGtag, {
    config: {
        id: "G-0YQC6S16S1", params: {
            send_page_view: false
        }
    }
});
app.mount('#app')
