<template>
  <div class="mx-auto text-center mt-2 mb-5">
    <router-link class="btn btn-lg btn-outline-dark" :to="{name:'Redirect'}">
      <p class="m-0">{{ FA_LAN.MAIN.POWER_BY }}</p>
    </router-link>
  </div>
</template>

<script>
import persian from "../fa-IR";

export default {
  name: "PowerBy",
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>