import pwa from "./includes/pwa";
import main from "./includes/main";
import login from "./includes/login";
import item from "./includes/item";
import introduction from "./includes/introduction";

const persian = {
    PWA: pwa,
    MAIN: main,
    LOGIN: login,
    ITEM: item,
    INTRODUCTION: introduction,
}

export default persian;