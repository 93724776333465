import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Redirect from "../views/Redirect";

const routes = [
    {path: '/:username?', name: 'Home', component: Home},
    {path: '/r', name: 'Redirect', component: Redirect},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
