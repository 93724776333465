const main = {
    NO_INTERNET: "دسترسی به اینترنت وجود ندارد",
    BACK: "برگشت",
    FOOTER: "طراحی و توسعه توسط گروه نگاه",
    APP_NAME: "اینستاکاتالوگ: کاتالوگ ساز محصولات و خدمات",
    CATALOG_NOT_FOUND: "کاتالوگ پیدا نشد",
    BACK_TO_MAIN: "بازگشت به صفحه اصلی",
    POWER_BY: "طراحی شده با اینستاکاتالوگ",
}

export default main