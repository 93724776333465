<template>
  <div class="d-flex justify-content-center align-items-center vh-100 text-muted">
    <div class="text-center">
      <i class="fal fa-meh big-icon"></i>
      <h5 class="mb-3 mt-4">{{ FA_LAN.MAIN.CATALOG_NOT_FOUND }}</h5>
      <router-link class="btn btn-success btn-lg" :to="{name:'Redirect'}">{{ FA_LAN.MAIN.BACK_TO_MAIN }}</router-link>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR";

export default {
  name: "NotFound",
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>