const item = {
    CATEGORY:"دسته: ",
    MAIN_PRICE_CATALOG:"قیمت:",
    PRICE_CATALOG:"با تخفیف:",
    TOMAN:"تومان",
    FREE:"رایگان",
    EMPTY_ITEM:"محصول/ خدماتی تعریف نشده است",
    EMPTY_CATEGORY_ITEM:"محصول/ خدماتی در این دسته تعریف نشده است",
    ALL_ITEMS:"همه",
}

export default item
